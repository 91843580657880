import {
  EmployeeInformationConfirmation,
  EmployeeInformationForm,
  InternalStepProgress,
  PageWithIntro,
  WizardLayout,
} from 'components';

import React, { Fragment, useState } from 'react';

import {
  allState as allSkipState,
  createSkippableSectionBuilderForKey,
  employeesSkipKeyBuilder,
} from '../../../utilities/skippedEntries';
import {
  createQueriesHook as createEmployeeQueries,
  allState as getEmployeesState,
} from '../../../utilities/employees';

import { deleteFormElement, handleFormSubmit } from '../../../utilities/formstate';

import { WistiaVideo } from 'components/widgets/WistiaVideo';

import { createQueriesHook as createAllQueries } from '../../../utilities/allQueries';

import { createQueriesHook as createIntroQueries } from '../../../utilities/introInformation';

import { createQueriesHook as createLicenseQueries } from '../../../utilities/licenses';

import { employeesStep } from '../../../utilities/stepDefinitions';

import { stepIsComplete } from '../../../utilities/steps';

import { useTracking } from '../../../utilities/hooks';

const MainView = ({ nextStep }) => {
  const introInformation = createIntroQueries();
  const { employeeState } = getEmployeesState();
  const allQueries = createAllQueries();
  const { skipState } = allSkipState();
  const { skippingEmployees, vehiclesAreSetup } = allQueries;

  const employeeQueries = createEmployeeQueries({
    employeeState,
    introQueries: introInformation,
    licenseQueries: createLicenseQueries(),
  });

  const submitData = handleFormSubmit(employeeState);

  const [idToEdit, setIdToEdit] = useState(null);

  const isEditingEmployee = idToEdit !== null;

  const employeeDetails = () =>
    isEditingEmployee
      ? { idToEdit, ...employeeQueries.employeeDetails[idToEdit] }
      : employeeQueries.newEmployee;

  const stepCompleted = stepIsComplete(employeesStep);

  const deleteEmployee = id => {
    deleteFormElement(employeeState, id);
  };

  const viewEmployeeList = backInternalStep => {
    setIdToEdit(null);
    backInternalStep();
  };

  const editEmployee = (id, backInternalStep) => {
    setIdToEdit(id);
    backInternalStep();
  };

  const handleSubmit = values => {
    const changes = isEditingEmployee ? { id: idToEdit, ...values } : values;
    submitData(changes);
    setIdToEdit(null);
  };

  const skippableSectionBuilder = createSkippableSectionBuilderForKey({
    label: 'I have no employees',
    keyBuilder: employeesSkipKeyBuilder,
    skipState,
    canSkip: allQueries.canSkipEmployees,
  });

  return (
    <Fragment>
      <h1>Add Employee/Owner Details</h1>
      <WistiaVideo id={employeesStep.intro.videoID} />
      <InternalStepProgress
        nextStep={nextStep}
        stepsConfig={[
          {
            step: 1,
            label: '1. Add Employee/Owner Details',
            component: (
              <EmployeeInformationForm
                initialValues={employeeDetails()}
                canCancel={isEditingEmployee || employeeQueries.hasEmployees}
                isEditingEmployee={isEditingEmployee}
                onCancel={backInternalStep => viewEmployeeList(backInternalStep)}
                onSubmit={handleSubmit}
                introInformation={introInformation}
                skippingEmployees={skippingEmployees}
                vehiclesAreSetup={vehiclesAreSetup}
                skippableSectionBuilder={skippableSectionBuilder}
              />
            ),
          },
          {
            step: 2,
            label: '2. Confirm Employee/Owner Details',
            component: (
              <EmployeeInformationConfirmation
                stepCompleted={stepCompleted}
                initialValues={employeeState.values}
                working={employeeState.working || false}
                editEmployee={(id, backInternalStep) =>
                  editEmployee(id, backInternalStep)
                }
                deleteEmployee={deleteEmployee}
                introInformation={introInformation}
                skippableSectionBuilder={skippableSectionBuilder}
                skippingEmployees={skippingEmployees}
              />
            ),
          },
        ]}
      />
    </Fragment>
  );
};

const EmployeesPage = props => {
  useTracking('Employees');

  return (
    <PageWithIntro step={employeesStep}>
      <WizardLayout text>
        <MainView {...props} />
      </WizardLayout>
    </PageWithIntro>
  );
};

export default EmployeesPage;
